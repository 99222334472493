import Service from "../Service";

const resource = "entrymaterialraw/"

export default {

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID },
        });
    },

    PrfEntryMaterialRawDownload(obj, requestID) {

        return Service.post(resource + "PrfEntryMaterialRawDownload", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    }

}